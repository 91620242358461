import React, { useCallback, useMemo, useState } from "react";

import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import Typography from "@mui/material/Typography";
import Pagination from "@mui/material/Pagination";

import { getColorMap } from "../../helpers";
import { CPQI_GRADES } from "../../constants";
import styled from "@mui/material/styles/styled";
import Tooltip from "@mui/material/Tooltip";
import tooltipClasses from "@mui/material/Tooltip/tooltipClasses";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TableSortLabel from "@mui/material/TableSortLabel";
import { visuallyHidden } from "@mui/utils";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontSize: 14,
  borderLeft: "1px solid #e1e8f4",
  color: "#647590",
  fontSize: "0.95rem",
  padding: "15px 24px",
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
}));

const headCells = [
  {
    field: "utitlity_name",
    label: "UTILITY"
  },
  {
    field: "cpqi",
    label: "CPQI"
  },
  {
    field: "po_and_sag_and_swell_warning_events",
    label: "ALL EVENTS INDEX"
  },
  {
    field: "po_events",
    label: "POWER OUTAGE INDEX"
  },
  {
    field: "sag_warning_events",
    label: "BROWNOUT INDEX"
  },
  {
    field: "swell_warning_events",
    label: "SURGES INDEX"
  },
]

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const MyToolTip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#647590",
    padding: "8px 12px",
    fontSize: 11,
    borderRadius: "4px",
  },
}));

const DataGrid = ({
  data = [],
  loadMap,
  fetchDataForModal,
  onModalToggle,
  setModalContent,
}) => {

  const [searchText, setSearchText] = useState("");
  const handleSearchTextChange = ({ target: { value } }) => {
    setSearchText(value);
  };


  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const handleSelectChange = useCallback(({ target: { value } }) => {
    setPerPage(() => value);
	setPage(() => 0);
  }, [setPerPage, setPage]);

  const nationalData = data.find((item) => item['utitlity_name'].includes("NATIONAL AVERAGE"));

  const rows = useMemo(
    () => {
      const filteredData = data.filter((item) => {
        return !item['utitlity_name'].includes("NATIONAL AVERAGE");
      });
      return searchText
        ? filteredData.filter(
          (row) =>
            !Object.values(row).every(
              (value) =>
                !String(value)
                  .toLowerCase()
                  .includes(searchText.toLowerCase())
            )
        )
        : filteredData;
    },
    [searchText, data]
  );

  const [sortModel, setSortModel] = React.useState([
    { field: "cpqi", sort: "desc" },
  ]);
  const handleSortChange = ({ target: { value } }) => {
    const values = value.split("-");
    setOrderBy(values[0]);
    setOrder(values[1]);
    setSortModel([{ field: values[0], sort: values[1] }]);

    if (window.dataLayer) {
      window.dataLayer.push({
        event: 'event',
        eventProps: {
          category: 'table',
          action: 'sort',
          label: "Changed the table sort type.",
          value
        }
      });
    }
  };

  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("cpqi");

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    setSortModel([{ field: property, sort: isAsc ? "desc" : "asc" }]);
  };

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };

  return (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          alginItems: "center",
          marginBottom: "2rem",
        }}
      >
        <Typography>{`Showing ${perPage * page + 1} to ${perPage * (page + 1) > rows.length
          ? rows.length + 1
          : perPage * (page + 1)
          } of ${rows.length + 1} elements`}</Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            marginLeft: "auto"
          }}
        >
          <FormControl variant="outlined" className={"search-field"} style={{ marginRight: "1rem" }}>
            <OutlinedInput
              sx={{ "& .MuiInputBase-input": { fontSize: "20px" }, backgroundColor: "#F2F5FA !important" }}
              id="outlined-adornment-weight"
              onChange={handleSearchTextChange}
              value={searchText}
              endAdornment={
                <InputAdornment position="end">
                  <MagnifyingGlassIcon
                    style={{ width: "24px", height: "24px" }}
                  />
                </InputAdornment>
              }
              aria-describedby="outlined-weight-helper-text"
              inputProps={{
                "aria-label": "weight",
                placeholder: "SEARCH",
              }}
            />
          </FormControl>

          <Box display="flex" sx={{}} className={"order-by"} style={{ marginRight: "1rem" }}>
            <div className={"order-by-inner"}>
              <span>Order By: &nbsp;</span>
              <Select
                value={`${sortModel[0]["field"]}-${sortModel[0]["sort"]}`}
                onChange={handleSortChange}
                input={<OutlinedInput />}
              >
                <MenuItem value="cpqi-desc">CPQI (WORST TO BEST)</MenuItem>
                <MenuItem value="cpqi-asc">CPQI (BEST TO WORST)</MenuItem>
                <MenuItem value="utitlity_name-asc">UTILITY (A-Z)</MenuItem>
                <MenuItem value="utitlity_name-desc">UTILITY (Z-A)</MenuItem>
                <MenuItem value="po_and_sag_and_swell_warning_events-desc">
                  ALL EVENTS INDEX (HIGHEST TO LOWEST)
                </MenuItem>
                <MenuItem value="po_and_sag_and_swell_warning_events-asc">
                  ALL EVENTS INDEX (LOWEST TO HIGHEST)
                </MenuItem>
                <MenuItem value="po_events-desc">
                  POWER OUTAGE INDEX (HIGHEST TO LOWEST)
                </MenuItem>
                <MenuItem value="po_events-asc">
                  POWER OUTAGE INDEX (LOWEST TO HIGHEST)
                </MenuItem>
                <MenuItem value="sag_warning_events-desc">
                  BROWNOUT INDEX (HIGHEST TO LOWEST)
                </MenuItem>
                <MenuItem value="sag_warning_events-asc">
                  BROWNOUT INDEX (LOWEST TO HIGHEST)
                </MenuItem>
                <MenuItem value="swell_warning_events-desc">
                  SURGES INDEX (HIGHEST TO LOWEST)
                </MenuItem>
                <MenuItem value="swell_warning_events-asc">
                  SURGES INDEX (LOWEST TO HIGHEST)
                </MenuItem>
              </Select>
            </div>
          </Box>

          <Box display="flex" sx={{}} className={"show-limiter"}>
            <div className={"show-limiter-inner"}>
              <span>Show: &nbsp;</span>
              <Select
                value={perPage}
                label="Show"
                onChange={handleSelectChange}
                input={<OutlinedInput />}
              >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={25}>25</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
              </Select>
            </div>
          </Box>
        </Box>
      </Box>

      <TableContainer component={Paper} style={{ position: "relative", borderRadius: "1rem", boxShadow: "none", border: "1px solid #E1E8F4" }}>
        <Table style={{ position: "relative", borderRadius: "4px" }}>
          <TableHead>
            <TableRow>
              {headCells.map((headCell, key) => (
                <TableCell
                  sx={{
                    borderLeft: "1px solid #e1e8f4",
                    fontSize: "20px",
                    fontFamily: "Montserrat,Arial,Helvetica,sans-serif",
                    lineHeight: 1.2,
                    textOverflow: "unset",
                    whiteSpace: "break-spaces",
                    color: "#647590",
                    fontWeight: 700,
                    textAlign: "inherit",
                    textTransform: "uppercase",
                    padding: '15px, 24px',
                    "&:hover": {
                      cursor: "pointer",
                      "& .MuiTableSortLabel-root": {
                        color: 'rgba(0, 0, 0, 0.6)',
                        "& .MuiTableSortLabel-icon": {
                          opacity: "0.5"
                        }
                      }
                    }
                  }}
                  key={headCell.field}
                  align={"left"}
                  padding={headCell.disablePadding ? "none" : "normal"}
                  sortDirection={orderBy === headCell.field ? order : false}
                  onClick={createSortHandler(headCell.field)}
                >
                  <TableSortLabel
                    active={orderBy === headCell.field}
                    direction={orderBy === headCell.field ? order : "asc"}
                  >
                    {headCell.label}
                    {orderBy === headCell.field ? (
                      <Box component="span" sx={visuallyHidden}>
                        {order === "desc" ? "sorted descending" : "sorted ascending"}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>

            {nationalData && (
              <StyledTableRow className={`national-average-row sticky-row`}>
                <StyledTableCell component="th" scope="row">
                  <div
                    style={{
                      display: "flex",
                      gap: "8px",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "100%",
                    }}>
                    <p className={`MuiTypography-root MuiTypography-body1`} style={{ fontSize: "1.00rem", fontWeight: "bold" }}>{nationalData.utitlity_name}</p>
                  </div>
                </StyledTableCell>
                <StyledTableCell align="right">
                  <span className={`grid-data-block`}>
                    <span className={`grid-data-value`}>
                      <strong>{nationalData.cpqi.toFixed(2)}</strong>
                    </span>
                    <span
                      className={`grid-data-color`}
                      style={{ backgroundColor: getColorMap(nationalData.cpqi, CPQI_GRADES, -1) }}
                    ></span>
                  </span>
                </StyledTableCell>
                <StyledTableCell align="right">{nationalData.po_and_sag_and_swell_warning_events}</StyledTableCell>
                <StyledTableCell align="right">{nationalData.po_events}</StyledTableCell>
                <StyledTableCell align="right">{nationalData.sag_warning_events}</StyledTableCell>
                <StyledTableCell align="right">{nationalData.swell_warning_events}</StyledTableCell>
              </StyledTableRow>
            )}

            {stableSort(rows, getComparator(order, orderBy))
              .slice(page * perPage, page * perPage + perPage)
              .map((row) => (
                <StyledTableRow key={row.name}>
                  <StyledTableCell component="th" scope="row">
                    {row.utitlity_name === "NATIONAL AVERAGE" ? (
                      <div>{row.utitlity_name}</div>
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          gap: "8px",
                          alignItems: "center",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <MyToolTip title="SEE DETAILED GRAPH" placement="top" arrow>
                          <div
                            className="utility-cell-name"
                            style={{ cursor: "pointer", width: "100%" }}
                            tabIndex={0}
                            onClick={() => {
                              fetchDataForModal();
                              const modalOutput = {
                                by: 'utility',
                                type: `data`,
                                title: `${row.utitlity_name}<br/><span>Power Outage + Brownouts + Surges</span>`,
                                content: row.utitlity_name,
                                data: ``,
                              };
                              setModalContent(modalOutput);

                              if (window.dataLayer) {
                                window.dataLayer.push({
                                  event: 'event',
                                  eventProps: {
                                    category: 'utilities',
                                    action: 'click_row',
                                    label: "Opened view utility details modal.",
                                    value: row.utitlity_name
                                  }
                                });
                              }

                              // Show Modal
                              onModalToggle();
                            }}
                          >
                            <Typography>{row.utitlity_name}</Typography>
                          </div>
                        </MyToolTip>

                        <MyToolTip title="SEE ON MAP" placement="top" arrow>
                          <Box
                            sx={{ cursor: "pointer", height: "100%" }}
                            onClick={() => {
                              loadMap(row.utitlity_name);
                            }}
                          >
                            <svg
                              width="24"
                              height="28"
                              viewBox="0 0 24 28"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M4.57518 3.97533C8.67569 -0.125175 15.3239 -0.125176 19.4244 3.97533C23.5249 8.07584 23.5249 14.7241 19.4244 18.8246L11.9998 26.2492L4.57518 18.8246C0.474678 14.7241 0.474678 8.07584 4.57518 3.97533ZM11.9998 15.3C14.1537 15.3 15.8998 13.5539 15.8998 11.4C15.8998 9.24604 14.1537 7.49995 11.9998 7.49995C9.84589 7.49995 8.0998 9.24604 8.0998 11.4C8.0998 13.5539 9.84589 15.3 11.9998 15.3Z"
                                stroke="#647590"
                                strokeWidth="1.4"
                              />
                            </svg>
                          </Box>
                        </MyToolTip>
                      </div>
                    )}
                  </StyledTableCell>
                  <StyledTableCell align="right" style={{ color: "#2A4879" }}>
                    <span className={`grid-data-block`}>
                      <span className={`grid-data-value`}>
                        <strong>{row.cpqi.toFixed(2)}</strong>
                      </span>
                      <span
                        className={`grid-data-color`}
                        style={{ backgroundColor: getColorMap(row.cpqi, CPQI_GRADES, -1) }}
                      ></span>
                    </span>
                  </StyledTableCell>
                  <StyledTableCell align="right">{row.po_and_sag_and_swell_warning_events}</StyledTableCell>
                  <StyledTableCell align="right">{row.po_events}</StyledTableCell>
                  <StyledTableCell align="right">{row.sag_warning_events}</StyledTableCell>
                  <StyledTableCell align="right">{row.swell_warning_events}</StyledTableCell>
                </StyledTableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Box
        className="showing-count-information"
        sx={{ display: "flex", alignItems: "center", paddingY: "32px" }}>
        <>
          <Typography>{`Showing ${perPage * page + 1} to ${perPage * (page + 1) > rows.length
            ? rows.length + 1
            : perPage * (page + 1)
            } of ${rows.length + 1} elements`}</Typography>
          <Box sx={{ flexGrow: 1 }} />
          <Pagination
            count={parseInt(
              rows.length / perPage + (rows.length % perPage ? 1 : 0)
            )}
            onChange={(_, value) => setPage(value - 1)}
            page={page + 1}
            shape="rounded"
          />
        </>
      </Box>
    </React.Fragment>
  );
};

export default DataGrid;
