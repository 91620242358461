import { CheckCircleIcon } from "@heroicons/react/24/outline";
import { Card } from "react-bootstrap";

const NewsletterSuccess = () => (
	<Card className="text-center rounded-4 border-0">
		<Card.Body className="p-5 fs-16">
			<CheckCircleIcon className="mb-4" color="#5DC26E" style={{ width: "30px", height: "30px" }} />
			<h3>THANKS FOR SIGNING UP</h3>
			<p>We'll keep you posted!</p>
		</Card.Body>
	</Card>
);

export default NewsletterSuccess;
