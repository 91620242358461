const HeaderDesign = () => {
  return (
    <div className={`header-design`}>
      {/* <div className={`header-design-outer`}>
                <div className={`header-design-inner`}>
                    <div className={`header-design-back`} />
                    <div className={`header-design-mid`} />
                    <div className={`header-design-fore`} />
                </div>
            </div> */}
      <div className={`header-design-bar`}>
        <div className={`header-design-bar-inner`} />
      </div>
    </div>
  );
};

export default HeaderDesign;
