import {Row, Col} from 'react-bootstrap';

import content from '../../assets/json/content.json';

const { links } = content;

const Footer = () => {
    const today = new Date();

    return (
        <footer>
            <div className={`footer-outer`}>
                <div className={`footer-inner`}>
                    <Row>
                        <Col>
                            <p className={`copyright`}>
                                <span>&copy; {today.getFullYear()} Whisker Labs Inc. - Ting. All Rights Reserved</span>
                                <a href={links.accessibility} title={`View Accessibility Statement`} target={`_blank`}>Accessibility Statement</a>
								<a href={links.faq} title="Frequently Asked Questions" target="_blank">FAQs</a>
                            </p>

                            <p className={`branding`}><a href={`https://www.yokoco.com`} title={`Open Yoko Co Website`} target={`_blank`}>Website by Yoko Co</a></p>
                        </Col>
                    </Row>
                </div>
            </div>
        </footer>
    );
}

export default Footer;