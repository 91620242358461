export const MAP_CFG = {
  limit_points: [],
  map_latitude: 37.5,
  map_longitude: -95.0,
  zoom_start: 5,
};

export const MONTHS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const PO_GRADES = [
  0, 0.025, 0.05, 0.1, 0.15, 0.25, 0.35, 0.5, 0.75, 1.25, 1.5,
].map(function (item) {
  return (item * 2) / 5;
});
export const SAG_GRADES = [
  0, 0.025, 0.05, 0.1, 0.15, 0.25, 0.35, 0.5, 0.75, 1.25, 1.5,
].map(function (item) {
  return item / 3;
});
export const SWELL_GRADES = [
  0, 0.0125, 0.025, 0.0375, 0.05, 0.1, 0.15, 0.2, 0.3, 0.4, 0.5,
].map(function (item) {
  return item / 5;
});
export const PO_SAG_SWELL_GRADES = [
  0, 0.05, 0.1, 0.2, 0.3, 0.5, 0.7, 1.0, 1.5, 2.5, 3,
].map(function (item) {
  return item / 3;
});
export const CPQI_GRADES = [
  0, 0.02, 0.04, 0.08, 0.12, 0.2, 0.28, 0.4, 0.6, 1, 1.2,
];
