import { NewspaperIcon } from '@heroicons/react/24/outline';

const InfoBox = () => {
    return (
        <section className={`infobox`}>
            <NewspaperIcon />

            <h3>Member of the Press?</h3>

            <p>Contact <a href={`mailto:media@whiskerlabs.com`} title={`Click to Email media@whiskerlabs.com`}>media@whiskerlabs.com</a> for answers to questions or additional data for your story.</p>
        </section>
    );
}

export default InfoBox;