import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { getColorMap, getGradeScale } from "../../helpers";

import { ChevronRightIcon, ChevronLeftIcon } from "@heroicons/react/24/solid";

const Legend = () => {
  const [params, _] = useSearchParams();
  const pqType = params.get("category");

  const gradeElems = useMemo(() => {
    const elems = [];

    [
      getGradeScale(pqType)[0],
      ...getGradeScale(pqType),
      getGradeScale(pqType)[10] + 1,
    ].forEach((color, index, array) => {
      if (index === 12 || index === 0) return;
      const elem = (
        <li
          id={`legend-item-${index}`}
          className={`legend-value-item`}
          key={index}
        >
          <span
            className={`legend-value-color`}
            style={{
              borderColor: "rgba(255, 255, 255, 0.3)",
              backgroundColor: getColorMap(array[index + 1], array, 1),
            }}
          ></span>

          <span className={`legend-value-numbers`}>
            {index === 0 && color.toFixed(3)}

            {index === 11 && color.toFixed(3) + "+"}

            {index !== 0 &&
              index !== 11 &&
              color.toFixed(3) + " - " + array[index + 1].toFixed(3)}
          </span>
        </li>
      );

      elems.push(elem);
    });

    return elems;
  }, [pqType]);

  return (
    <section className={`map-legend`} style={{ zIndex: "1000" }}>
      <div className={`map-legend-inner`}>
		<div className="legend-range-container">
			<ul className={`legend-range`}>
			<li className={`legend-range-label worse`}>Worse</li>
			<li className={`legend-range-arrow`}>
				<ChevronRightIcon className={"legend-chevron chevron-right"} />
				<ChevronLeftIcon className={"legend-chevron chevron-left"} />
			</li>
			<li className={`legend-range-label better`}>Better</li>
			</ul>
		</div>

        <ul className={`legend-values-list`}>{gradeElems}</ul>
      </div>
    </section>
  );
};

export default Legend;
