import { useState } from "react";
import { Form, Spinner } from "react-bootstrap";
import {
  ActiveCampaignInputs,
  handleActiveCampaignSubmit,
} from "active-campaign-react";
import { useForm } from "react-hook-form";

import { ChatBubbleLeftEllipsisIcon } from "@heroicons/react/24/outline";

import NewsletterSuccess from "./NewsletterSuccess";

const Newsletter = () => {
  const { newsletterSubmission, setNewsletterSubmission} = useState(null);
  const { register, formState: { errors }, handleSubmit, setError } = useForm({ reValidateMode: 'onSubmit'});
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const onSubmit = async (data) => {
	setLoading(true);
    handleActiveCampaignSubmit(
      data,
      process.env.REACT_APP_SUBDOMAIN,
      process.env.REACT_APP_FORM_ID
    )
      .then(() => {
		setSuccess(true);
      })
      .catch((error) => {
		setError( 'submission', `Submission Error: ${error.message}` );
      })
	  .finally(() => {
		setLoading(false);
	  });
  };

  return success
	? <NewsletterSuccess />
	: (
	<section className={`newsletter`}>
		<ChatBubbleLeftEllipsisIcon />

		<h3>Don't see your Region or Utility?</h3>

		<p>Sign up to be notified when we have data for you!</p>

		<div className={`form-group`}>
			<Form noValidate onSubmit={handleSubmit(onSubmit)}>
				<ActiveCampaignInputs formId={process.env.REACT_APP_FORM_ID} />
				<input
					id="name"
					className={`form-control`}
					placeholder={`Your name`}
					name="name"
					style={{
					position: "absolute",
					top: "-1px",
					left: "1px",
					margin: 0,
					padding: 0,
					width: "1px",
					height: "1px",
					overflow: "hidden",
					}}
					{...register("name", { required: false })}
				/>

				<Form.Group controlId="email">
				<Form.Control
					isInvalid={errors?.email}
					placeholder={`Your email address`}
					name="email"
					type="email"
					{...register("email", {
						pattern: {
							message: "Please provide a valid email address",
							value: /^[A-Za-z0-9._+\-\']+@[A-Za-z0-9.\-]+\.[A-Za-z]{2,}$/
						},
						required: "An email address is required."
					})}
				/>
				{errors?.email ? <Form.Control.Feedback className="mt-3" type="invalid">{errors.email.message}</Form.Control.Feedback> : null}
				</Form.Group>

				<button
					className={`btn btn-primary btn-signup`}
					disabled={loading}
					title={`Click to Submit Email Address`}
					type={"submit"}
				>
					{loading ? <Spinner animation="border" size="sm" /> : `Sign Up`}
				</button>

				{errors?.submission ? <Form.Text className="mt-4 text-danger">{errors?.submission?.message}</Form.Text> : null}

			</Form>
		</div>
	</section>)
};

export default Newsletter;
